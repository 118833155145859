import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import idLocale from 'vuetify/es5/locale/id'
import enLocale from 'vuetify/es5/locale/en'

Vue.use(Vuetify)

const opts = {
  lang: {
    locales: {
      idLocale,
      enLocale
    },
    current: 'idLocale',
  },
  iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#FE0000",
        secondary: "#303030",
        accent: "#673ab7",
        error: "#f44336",
        warning: "#ffc107",
        info: "#4caf50",
        success: "#009688",
      },
      dark: {
        primary: "#FE0000",
        secondary: "#303030",
        accent: "#673ab7",
        error: "#f44336",
        warning: "#ffc107",
        info: "#4caf50",
        success: "#009688",
      }
    },
  },
}

export default new Vuetify(opts)
