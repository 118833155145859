import env from "@/env";
import Echo from "laravel-echo";
import socketIO from 'socket.io-client';

/**
 * Integrate web socket with socket.io + laravel echo (old echo server). abandoned.
 *
 * https://github.com/tlaverdure/laravel-echo-server
 * https://socket.io/docs/v2/
 * https://laravel.com/docs/8.x/broadcasting
 */
class LaravelEchoSocketIOWebSocket {
  init() {
    if (this.isReadyWebSocket()) return false;

    let isEnabled = env.WEBSOCKET_ENABLED === 'true';
    let isHttps = env.USE_HTTPS === 'true';

    let host = env.APP_BASE_URL
    let isPort = env.WEBSOCKET_PORT !== '';
    if(isPort){
      host += ':' + env.WEBSOCKET_PORT
    }

    let config = {
      broadcaster: 'socket.io',
      host: host,
      withCredentials: true,
      autoConnect: isEnabled,
      reconnection: isEnabled,
      reconnectionDelay: 500,
      //maxReconnectionAttempts: Infinity,
      transports: ['websocket', 'wss', 'ws'],
      //secure: true,
      wsHost: env.APP_BASE_URL,
    };

    if (isPort) {
      config = Object.assign({}, config, {
        wsPort: env.WEBSOCKET_PORT,
        wssPort: env.WEBSOCKET_PORT,
      });
    }

    window.io = socketIO;

    window.Echo = new Echo(config);

    return window.Echo;
  }

  isReadyWebSocket() {
    return window.Echo ? true : false;
  }

  destroy() {
    window.Echo = null;
    window.io = null;
  }

  isConnected() {
    if (!this.isReadyWebSocket()) return false;

    let result = window.Echo.connector.socket.on('connect', function () {});

    console.log('web socket connected ' + result.connected);
    return result.connected;
  }

  isDisconnected() {
    if (!this.isReadyWebSocket()) return false;

    let result = window.Echo.connector.socket.on('disconnect', function () {});

    console.log('web socket disconnected ' + result.disconnected);
    return result.disconnected;
  }
}

export default new LaravelEchoSocketIOWebSocket();
