import bootstrap from './bootstrap';
import env from '@/env';
import Vue from 'vue';

import { InertiaProgress } from '@inertiajs/progress';
// import { App, plugin } from "@inertiajs/inertia-vue";
import { createInertiaApp } from '@inertiajs/inertia-vue';
import route from 'ziggy';
import { Ziggy } from './ziggy';
import InertiaDirective from './InertiaDirective';
import vuetify from './plugins/vuetify';

import DatetimePicker from 'vuetify-datetime-picker';
import VueMeta from 'vue-meta';
import { localize } from 'vee-validate';
import idLocale from 'vee-validate/dist/locale/id.json';

// import dayjs from 'dayjs';
// require('dayjs/locale/id');

import VuetifyConfirm from 'vuetify-confirm';
import Snackbar from './plugins/snackbar';
import UserRolePermissionPlugin from './plugins/UserRolePermissionPlugin';
// import ServiceWorkerPlugin from "./plugins/ServiceWorkerPlugin";
import WebSocketPlugin from './plugins/WebSocketPlugin';
import BlinkTitlePlugin from './plugins/BlinkTitlePlugin';
import './filters';
import './CustomVeeValidate';
// import "./SentryService";

InertiaProgress.init({
  delay: 150, //milliseconds
  color: '#29d',
  includeCSS: true,
  showSpinner: false,
});

// Vue.use(plugin);
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
});

localize({
  idLocale,
});
localize('idLocale');
Vue.use(DatetimePicker);

// dayjs.locale('id')

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Ok',
  buttonFalseText: 'Batal',
  buttonTrueColor: 'primary',
  buttonFalseColor: 'grey',
  buttonTrueFlat: false,
  buttonFalseFlat: true,
  color: 'primary',
  icon: 'warning',
  title: 'Warning',
  width: 350,
  property: '$confirm',
});

Vue.directive('inertia', InertiaDirective);

Vue.use(Snackbar);
Vue.use(UserRolePermissionPlugin);
// Vue.use(ServiceWorkerPlugin);
Vue.use(WebSocketPlugin);
Vue.use(BlinkTitlePlugin);

Vue.mixin({
  methods: {
    route: (name, params, absolute, config = Ziggy) =>
      route(name, params, absolute, config),
  },
});

bootstrap(Vue);

createInertiaApp({
  resolve: (name) => import(`./Pages/Admin/${name}`),
  setup({ el, App, props, plugin }) {
    Vue.use(plugin);
    const vm = new Vue({
      metaInfo: {
        titleTemplate: (title) => {
          return title ? `${title} - ` + env.APP_NAME : env.APP_NAME;
        },
      },
      vuetify,
      render: (h) => h(App, props),
    }).$mount(el);

    // HACK: data-page attribute not removed after mount
    vm.$nextTick(() => vm.$el.removeAttribute('data-page'));
  },
});
