import Vue from "vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

Vue.filter("formatNumber", function(value) {
    if (!value) return value;
    return value.toLocaleString("id-ID");
});

Vue.filter("formatRupiah", function(originalValue) {
  const value = Number(originalValue);
  if(isNaN(value)) {
    return originalValue;
  }
  return `${value < 0 ? '- ' : ''}Rp. ${Math.abs(value).toLocaleString("id-ID")}`;
});

Vue.filter("dateTimeAgo", function(date) {
    if (!date) return;
    date = dayjs(date);
    return date.isValid() ? date.fromNow() : "";
});

Vue.filter("formatDate", function(date, format = "DD MMMM YYYY HH:mm") {
    if (!date) return;
    date = dayjs(date);
    return date.isValid() ? date.format(format) : "";
});
