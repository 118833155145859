// import env from "@/env";
// import LaravelEcho from '@/Components/WebSocket/LaravelEchoPusherWebSocket';
import LaravelEcho from '@/Components/WebSocket/LaravelEchoSocketIOWebSocket';

/**
 * Integrate web socket with laravel echo
 *
 * https://laravel.com/docs/8.x/broadcasting#introduction
 *
 * If you use cloudflare, please use port 8443 (https) / 2095 (http).
 * Because port 6001 is blocked, must be opened in cloudflare.
 * https://support.cloudflare.com/hc/en-us/articles/200169156-Which-ports-will-Cloudflare-work-with-
 */
class WebSocketService {
  // constructor() {
      //this.initWebSocket();
  // }

  initWebSocket() {
    if(this.isReadyWebSocket())return false;

    LaravelEcho.init()
  }

  destroy()
  {
    LaravelEcho.destroy()
  }

  isReadyWebSocket() {
    return window.Echo ? true : false;
  }

  isConnected() {
    return LaravelEcho.isConnected()
  }

  isDisconnected() {
    return LaravelEcho.isDisconnected();
  }

  leaveChannel(channel) {
    if (!channel) return false;
    if (!this.isReadyWebSocket()) return false;

    window.Echo.leave(channel);
  }

  listen(channel) {
    if (!channel) return false;
    if (!this.isReadyWebSocket()) return false;

    return window.Echo.channel(channel);
  }

  joinPresenceChannel(channel) {
    if (!channel) return false;
    if (!this.isReadyWebSocket()) return false;

    return window.Echo.join(channel);
  }

  joinPrivateChannel(channel) {
    if (!channel) return false;
    if (!this.isReadyWebSocket()) return false;

    //console.log(window.Echo.socketId())
    return window.Echo.private(channel);
  }

  isJoinedChannel(){
    if (this.isDisconnected()) return false;
    let socketID = window.Echo.socketId();
    if (typeof socketID === "undefined") return false;

    return socketID ? true : false;
  }
}

export default new WebSocketService();
