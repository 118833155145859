export default {
  enableCheckAcl() {
    //set false if you don't want permission check
    return true;
  },
  allAuthUserToLocalStorage(authUser, authRoles, authPermissions) {
    this.authSuperAdminToLocalStorage(authUser);
    this.authRolesToLocalStorage(authRoles);
    this.authPermissionsToLocalStorage(authPermissions);
  },
  authUserToLocalStorage(authUser) {
    if (!authUser) return false;
    this.authToLocalStorage(authUser, "authUser");
  },
  authSuperAdminToLocalStorage(authUser) {
    if (!authUser) return false;
    let jsonString = JSON.parse(JSON.stringify(authUser));
    this.authToLocalStorage(jsonString.super_admin.value, "authSU");
  },
  authRolesToLocalStorage(authRoles) {
    if (!authRoles) return false;
    this.authToLocalStorage(authRoles, "authRoles");
  },
  authPermissionsToLocalStorage(authPermissions) {
    this.authToLocalStorage(authPermissions, "authPermissions");
  },
  authToLocalStorage(props, itemNameInStorage) {
    let jsonString = JSON.stringify(props);
    let permissionStorage = localStorage.getItem(itemNameInStorage) === "true";

    if (!permissionStorage) {
      window.localStorage.setItem(itemNameInStorage, jsonString);
    } else {
      if (itemNameInStorage === "authSU") {
        window.localStorage.setItem(itemNameInStorage, jsonString);
      }
      if (permissionStorage.length !== jsonString.length) {
        window.localStorage.setItem(itemNameInStorage, jsonString);
      }
    }
  },
  clearAuthUserStorage() {
    window.localStorage.removeItem("authPermissions");
    window.localStorage.removeItem("authRoles");
    window.localStorage.removeItem("authUser");
    window.localStorage.removeItem("authSU");
  },
  getAuthLocalStorage(item) {
    let json = JSON.parse(localStorage.getItem(item));
    if (json === "undefined") return false;
    if (!json) return false;
    if (json.length < 1) return false;
    return json;
  },
  getPermission(permissionName) {
    let permissions = this.getPermissionsStorage();
    if (!permissions) return false;

    let data = permissions.filter(permission => {
      return permission.name === permissionName;
    });
    return data;
  },
  hasPermission(permissionName) {
    if (typeof permissionName !== 'string') return false;
    if (this.checkAuth()) return true;

    let data = this.getPermission(permissionName);
    let result = (data.length > 0);
    return result;
  },
  hasNoPermission(permissionName) {
    return !this.hasPermission(permissionName);
  },
  getRole(roleName) {
    let roles = this.getRolesStorage();
    if (!roles) return false;

    let data = roles.filter(role => {
      return role.name === roleName;
    });
    return data;
  },
  hasRole(roleName) {
    if (this.checkAuth()) return true;

    let data = this.getRole(roleName);

    let result = data.length > 0 ? true : false;
    return result;
  },
  hasNoRole(roleName) {
    if (this.checkAuth()) return true;

    let data = this.getRole(roleName);
    let result = data.length > 0 ? false : true;
    return result;
  },
  checkAuth() {
    if (!this.enableCheckAcl()) return true;
    if (this.isSuperAdmin()) return true;
    return false;
  },
  isSuperAdmin() {
    let su = this.getSUStorage();
    if (!su) return false;
    return parseInt(su) === 1 ? true : false;
  },
  getPermissionsStorage() {
    return this.getAuthLocalStorage("authPermissions");
  },
  getRolesStorage() {
    return this.getAuthLocalStorage("authRoles");
  },
  getSUStorage() {
    return this.getAuthLocalStorage("authSU");
  }
};
