/**
 * Implementation blinking title in title bar browser
 */
class BlinkTitle {
  start(opts) {
    if (!opts) opts = {};
    this.delay = opts.delay || 500;
    this.title = opts.title || document.title;
    this.message = opts.message || "";
    this.notifyOffPage = opts.notifyOffPage || false;
    this.duration = opts.duration || false;
    this.originalTitle = document.title;

    if (this.notifyOffPage) {
      this.hold = setInterval(
        function() {
          if (document.hidden) this.blinkText();
        }.bind(this),
        this.delay
      );
    } else {
      this.hold = setInterval(
        function() {
          this.blinkText();
        }.bind(this),
        this.delay
      );
    }

    if (this.duration) {
      setTimeout(
        function() {
          this.stop();
          document.title = this.originalTitle;
        }.bind(this),
        this.duration
      );
    }
  }

  blinkText() {
    document.title === this.title
      ? (document.title = this.message)
      : (document.title = this.title);
  }

  stop() {
    clearInterval(this.hold);
  }

  newNotif(opts){
    let defaultOptions = {
      duration: 5000 //ms
    };
    let newOpts = Object.assign({}, defaultOptions, opts);
    this.start(newOpts);
  }
}

export default new BlinkTitle();
