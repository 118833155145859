module.exports = preval`
  // increment this to see your changes in .env => 2

  const fs = require('fs')
  const path = require('path')
  const dotenv = require("dotenv");
  const dotenvExpand = require("dotenv-expand");

  const allowedEnv = [
    'APP_NAME',
    'APP_ENV',
    'APP_BASE_URL',
    'APP_URL',
    'IMG_URL',
    'NO_IMG_URL',
    'WEBSOCKET_ENABLED',
    'WEBSOCKET_PORT',
    'SENTRY_JS_DSN',
    'SENTRY_TRACES_SAMPLE_RATE',
    'SENTRY_RELEASE',
    'RECAPTCHA_SITE_KEY',
    'PUSHER_APP_CLUSTER',
    'MIX_PUSHER_APP_KEY',
    'MIX_PUSHER_HOST',
    'MIX_PUSHER_PORT',
    'USE_HTTPS'
  ]

  const parsed = dotenv.parse(fs.readFileSync(path.resolve('.env')))
  const { parsed:env } = dotenvExpand({ parsed, ignoreProcessEnv: true });

  const safeEnv = {}
  allowedEnv.forEach((key) => {
    safeEnv[key] = env[key]
  })
  module.exports = safeEnv
`;
