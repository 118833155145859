import { extend } from "vee-validate";

/**
 * Custom Vee Validate to validate content prose mirror scheme
 */
extend("required-prosemirror", {
  validate: value => {
    if (typeof value === "string" || value instanceof String){
      return true;
    }
    let content = value.content;
    if (!content && !Array.isArray(content) && content.length < 1) return false;

    console.log(content.length);

    if (content.length < 2) {
      if (content[content.length - 1].type === "paragraph") {
        if (!content[content.length - 1].content) return false;
      }
    }

    return true;
  },
  message: "{_field_} harus diisi"
});

/**
 * Custom Vee Validate to validate valid url
 */
extend("valid-url", {
  validate: value => {
    if(!value) return false
    let url;
    try {
      url = new URL(value);
    } catch (error) {
      console.log(error)
      return false;
    }
    return [url.protocol === "http:" || url.protocol === "https:"]
    // return true
  },
  message: "{_field_} URL tidak valid"
});

extend('password', {
  validate: (value) => {
    if (!value) return false;

    value = value.trim()

    if(value.indexOf(' ') >= 0)return false
    if (value.length < 8) return false
    return true
  },
  message: '{_field_} minimal 8 karakter, tidak mengandung spasi',
});
