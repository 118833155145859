import { EventBus } from '@/EventBus'

function Install (Vue) {
  // FIXME: don't use event bus, investigate why previous implementation
  // aren't working. Also investigate using vuex
  Vue.prototype.$snackbar = function(messages) {
    EventBus.$emit('add-snackbar', messages)
  }
}

export default Install
