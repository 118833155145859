// window._ = require('lodash');
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import qs from 'qs';
import env from '@/env';
import axios from 'axios';
// import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';
require('dayjs/locale/id');
let localeData = require('dayjs/plugin/localeData');
dayjs.extend(localeData);

/**
 * dayjs global configuration
 */
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.locale('id')
// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.tz.setDefault('Asia/Jakarta');


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the 'XSRF' token cookie.
 */

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.paramsSerializer = (params) => qs.stringify(params, { encode: false })
axios.defaults.withCredentials = true;

export default function(vue, isServer) {
  if(!isServer) {
    const token = Cookies.get('XSRF-TOKEN', { domain: '.' + env.APP_BASE_URL });

    if (token) {
      axios.defaults.headers.common['X-XSRF-TOKEN'] = token
    } else {
      console.error('XSRF token not found', document.cookie);
    }

    window.dayjs = dayjs;
    window.axios = axios;
  }

  vue.mixin({
    data() {
      return {
        axios: axios,
        dayjs: dayjs,
      };
    },
  });
}
